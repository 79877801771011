import cryptoJs from 'crypto-js'


const DESKey = 'Zpbk@2022#Ynlc';

// DES加密
export const encryptDes = (message) => {
    let keyHex = cryptoJs.enc.Utf8.parse(DESKey)
    let option = { mode: cryptoJs.mode.ECB, padding: cryptoJs.pad.Pkcs7 }
    let encrypted = cryptoJs.DES.encrypt(message, keyHex, option)
    return encrypted.ciphertext.toString()
};

// DES解密
export const decryptDes = (message) => {
    let keyHex = cryptoJs.enc.Utf8.parse(DESKey)
    let decrypted = cryptoJs.DES.decrypt(
        {
            ciphertext: cryptoJs.enc.Hex.parse(message)
        },
        keyHex,
        {
            mode: cryptoJs.mode.ECB,
            padding: cryptoJs.pad.Pkcs7
        }
    )
    return decrypted.toString(cryptoJs.enc.Utf8)
};
export const vlaidPassword = (pwd) => {
    // 密码中必须包含大小字母、数字、特称字符，至少8个字符，最多30个字符。
    // var regex = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,30}');
    // 密码中必须包含字母、数字、特称字符，至少8个字符，最多30个字符。
    // var regex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,30}');
    // 密码中必须包含字母、数字，至少8个字符，最多30个字符。
    // var regex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{8,30}');
    // let regex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{6, 20}');
    //  let regex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6, 30}');
    // let regex = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[^\w\s]).{3,}$/

    // 密码中必须包含字母、数字、特称字符，至少8个字符，最多30个字符。
    // let regex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,20}');
    // if (!regex.test(pwd)) {
    //     return false;
    // } else {
    //     return true;
    // }
    let _pwd = pwd.replace(/\s+/g, "").split(/\s*/);
    let len = _pwd.length;
    if (len < 8 || len > 20) {
        return false;
    }
    let upperLen = 0;
    let hasUpper = false;
    let lowderLen = 0;
    let hasLower = false;
    let numLen = 0;
    let hasNum = false;
    let charNum = 0;
    let hasChar = false;
    for (let i=0; i < len; i++) {
        if (_pwd[i].match(/[A-Z]/g)) {
            upperLen++;
        } else if (_pwd[i].match(/[a-z]/g)) {
            lowderLen++;
        } else if (_pwd[i].match(/[0-9]/g)) {
            numLen++;
        } else {
            charNum++;
        }
    }
    console.log(upperLen, lowderLen, numLen, charNum)
    hasUpper = upperLen > 0;
    hasLower = lowderLen > 0;
    hasNum = numLen > 0;
    hasChar = charNum > 0;
    return (hasUpper || hasLower) && hasNum && hasChar;
}
